//global imports
import React from 'react';
import {
  Row,
  Col
} from 'react-bootstrap';
import MathJax from 'react-mathjax';




//local imports
import './main.css';
import Calculator from "./../components/calculator/Calculator";
import { variablesSimple, variablesAdvanced } from './../configs/calclogic'

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.calcRef = React.createRef();
    this.state = {calcPos:88};
    this.handleScroll = this.handleScroll.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);

    this.mathJaxOpt = {
      tex2jax: {
          inlineMath: [],
          processEscapes: true
      },
      showMathMenu: false,
      showMathMenuMSIE: false,
      CommonHTML: { linebreaks: { automatic: true } },
      "HTML-CSS": { linebreaks: { automatic: true } },
             SVG: { linebreaks: { automatic: true } }
    }

  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize)
    var maxScroll = document.body.offsetHeight - window.innerHeight;
    var windowHeight = window.innerHeight;
    var footerHeight = document.getElementById('footer').clientHeight + 41; //+41 for offset from copyright bar.
    var calculatorHeight = document.getElementById('calculator').clientHeight + 10;
    this.setState({maxScroll:maxScroll, windowHeight:windowHeight, footerHeight:footerHeight, calculatorHeight:calculatorHeight});
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    // update state
    var maxScroll = document.body.offsetHeight - window.innerHeight;
    var windowHeight = window.innerHeight;
    var footerHeight = document.getElementById('footer').clientHeight + 41; //+41 for offset from copyright bar.
    this.setState({maxScroll:maxScroll, windowHeight:windowHeight, footerHeight:footerHeight});
    this.handleScroll();
  }

  handleScroll() {
    const paddingBottom = 50;
    var scroll = window.scrollY;
    if(scroll <= 78) {
      this.setState({calcPos: 88-scroll});
    } else if (scroll > this.state.maxScroll - this.state.footerHeight+window.innerHeight - this.state.calculatorHeight - paddingBottom - 8){
      this.setState({calcPos: window.innerHeight - this.state.calculatorHeight - this.state.footerHeight + this.state.maxScroll - scroll - paddingBottom});
    } else {
      this.setState({calcPos: 10});
    }
  }

  handleModeChange() {
    var calculatorHeight = document.getElementById('calculator').clientHeight + 10; //+10 from margin top
    this.setState({calculatorHeight: calculatorHeight});
    this.handleScroll();
  }

  render() {
    return (
      <Row>
        <Col lg="5" md="6">
          <div className="fix-calculator" style={{top: this.state.calcPos}}>
            <Calculator varSimple={variablesSimple} varAdvanced={variablesAdvanced} handle={this.handleModeChange}></Calculator>
          </div>
        </Col>
        <Col lg="7" md="6">
          <MathJax.Provider options={this.mathJaxOpt}>
            <h1>The Kanban Technique</h1>
            <div className="info-content">
            <p>
              <b>Kanban</b> is a simple technique for stochastic materials management. The goal is to work as quickly as possible with small batch sizes and small buffer storages, which are kept at the user operation. These stores will contain, for example, a maximum number of standard containers or bins holding a fixed number of items. The order batch size will be a set of containers. The kanban card is a means to identify the contents of the container and to release the order. One or more empty containers are either sent directly by work center employees to the supplier or collected by one of the supplier’s employees. The supplier executes the implied stock replenishment order and delivers it directly to the buffer. The kanban feedback loop is then closed. One of the tasks of long- and medium-term planning is to determine the type and number of kanban cards for each feedback loop.
            </p>
            </div>
            <h1>Kanban Calculation</h1>
            <div className="info-content">
            <p>
              When introducing a kanban system, you will inevitable face the question <b>How many kanban bins or cards do I need in my system to guarantee the availability of items?</b></p>
              <p>In its essence, the following condition needs to be satified: The entire quantity in the system must match the expected usage during lead time. Where the entire quantity is defined as number of kanban bins <MathJax.Node inline formula={'A'} /> times bin size <MathJax.Node inline formula={'k'} /> and the expected usage during lead time is calculated by multiplying average weekly demand <MathJax.Node inline formula={'WD'} /> and average lead time in weeks <MathJax.Node inline formula={'LT'} />.
            </p>
            <MathJax.Node formula={'A \\cdot k = {WD \\cdot LT}\\cdot (1+SF) + k'} />
            <p>
              The additional bin <MathJax.Node inline formula={'k'} /> at the end of the equation can be interpreted as the bin, that is in consumption. The safety factor <MathJax.Node inline formula={'SF'} /> is introduced to compensate for fluctuations in lead time and demand during lead time. 
            </p>
            <p>
              Usually, bin replenishment time is depending on the actual bin size. Our advanced version of the kanban calculator accounts for that by splitting replenishment times into three parts: Summed run times <MathJax.Node inline formula={'SRT'} /> per item, summed set up times <MathJax.Node inline formula={'SST'} /> and summed admin time <MathJax.Node inline formula={'SAT'} />, which includes all the time needed for transportation and administration. In adition, the advanced calculator takes into account, that transport batch size <MathJax.Node inline formula={'w'} /> might differ from the actual bin size <MathJax.Node inline formula={'k'} />.
            </p>
            <MathJax.Node formula={'A \\cdot k = {WD \\cdot \( w \\cdot k \\cdot SRT + SST + SAT\)}\\cdot (1+SF) + w \\cdot k'} />         
            </div>
            <h1>Kanban Requirements</h1>
            <div className="info-content">
            <p>Kanban can only guarantee availability when the following two conditions are met:</p>
            <p>
              <b>Contineous demand</b>: Demand should be as contineous as possible along the whole value-adding chain. That means that that production or procurement orders should be frequent and batch sizes should be small.
            </p>
            <p>
              <b>Flexible capacity</b>: In reality, there are few situations where demand is truly continuous. Therefore, capacity must be flexible enough to adapt to changes in load.
            </p>
            </div>
            <h1>Kanban and ABC/XYZ</h1>
            <div className="info-content">
            <p>
              Kanban systems are typically used for AX-articles in an ABC/XYZ classification. A-articles are the articles with the highest contribution to revenue. X-articles are the articles with the greatest continuity in demand.
            </p>
            </div>
          </MathJax.Provider>
        </Col>
      </Row>
    );
  }
}

export default Main;
