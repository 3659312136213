//global imports
import React from 'react';

//local imports
import notfoundStyle from './notfound.module.scss'

const Notfound = () => {
  return (
    <div className={notfoundStyle.content}>
      <div className={notfoundStyle.title}>404</div>
      <div className={notfoundStyle.text}>Oops. It seems that the page you are looking for does not exist anymore.</div>
    </div>
  );
}

export default Notfound;