//global imports
import React from 'react';

//local imports
import textStyle from './../configs/textstyle.module.scss';

const About = () => {
  return (
    <div className={textStyle.container}>
      <h2>About</h2>
      <p>This page is actually a by-product of a weekend-hack. The main goal of this hack was to get familiar with the popular react.js framework.</p>
      <p>I tried to come up with something useful to code, something that I and hopefully also other people could use in the future. As I work in supply chain and logistics, I thought it would be nice to have a kanban calculator that allows me to easily calculate the number of kanbans needed in a system in a simple and fast way. </p>
    </div>
  );
}

export default About;