import React from 'react';
import Button from '../button/Button';
import './Calculator.css';



class Calculator extends React.Component {
  constructor(props) {
    // initialize props
    super(props);

    // initialize state
    var allVars = new Array();
    for (let key in this.props.varSimple) {
      allVars.push(this.props.varSimple[key].init);
    }
    this.state = {
      mode: "simple",
      model: this.props.varSimple,
      vars: allVars
    };

    // bind functions
    this.inputChange = this.inputChange.bind(this);
    this.reset = this.reset.bind(this);
    this.renderInputs = this.renderInputs.bind(this);
    this.calculate = this.calculate.bind(this);
    this.changeMode = this.changeMode.bind(this);
  }

  changeMode() {
    if (this.state.mode === "simple") {
      this.setState({mode: "advanced", model: this.props.varAdvanced.slice()});
    } else {
      this.setState({mode: "simple", model: this.props.varSimple.slice()});
    }
    setTimeout(this.props.handle,20);
  }

  reset() {
    var allVars = new Array();
    for (let key in this.state.model) {
      allVars.push(this.state.model[key].init);
    }
    this.setState({vars: allVars});
  }

  inputChange(id, value) {
    // create copy of old state
    const vars = this.state.vars.slice();

    // set new variable
    vars[id] = value;

    // re-calculate variables that are influenced
    for (let key in this.state.model) {
      if (this.state.model[key].requirements === undefined) {
        // doNothing
      } else if (this.state.model[key].requirements.includes(parseInt(id))) {
        var result = this.calculate(key, vars);
        if (result != "nodef") {
          vars[key] = result;
          // hack to calculate 2nd time
          if(key == 5) {
            vars[8] = this.calculate(8, vars);
            vars[9] = this.calculate(9, vars);
          } 
        }
        
      }
    }

    // set new state
    this.setState({vars: vars});
  }

  calculate(key, vars) {
    // check if function exists
    console.log(this.state.model[key].function)
    console.log(this.state.model[5].name)

    if (this.state.model[key].function === undefined) {
      console.log("nofunc");
      return "nodef";
    }
    
    // check if all requirements are fulfilled

    var reqArray = this.state.model[key].requirements.slice();
    for (var index = 0; index < reqArray.length; index++) {
      console.log(this.state.model[reqArray[index]], reqArray[index])
      if (vars[reqArray[index]] === "") {
        return "nodef";
      }
    }

    // make sure values are calculated in the correct format
    var varsFloat = vars.map(function (x) { 
      return parseFloat(x); 
    });

    return this.state.model[key].function(varsFloat);
  }

  renderInputs(type) {
    var inputs = new Array();
    for (let key in this.state.model) {
      if (this.state.model[key].type === type) {
        var input = <Input
                      key={key}
                      id={key}
                      name={this.state.model[key].name}
                      value={this.state.vars[key]} 
                      unit={this.state.model[key].unit}
                      callback={this.inputChange}>
                    </Input>;
        inputs.push(input);
      }

    }
    return inputs;
  }

  render() {
    return (
      <div id="calculator">
        <div className="input-box">
          <div className="input-header"><div className="input-header-name">Parameters</div></div>
          {this.renderInputs("input")}
        </div>
        <div className="input-box">
          <div className="input-header"><div className="input-header-name">Results</div></div>
          {this.renderInputs("output")}
        </div>
        <div className="button-box">
          <Button name="Reset" callback={this.reset}></Button>
          <Button name={(this.state.mode === "simple")?"Advanced":"Simple"} callback={this.changeMode}></Button>
        </div> 
      </div>
    );
  }
}

class Input extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    var filteredValue = event.target.value.replace(/[^0-9.]/g, "");
    if (filteredValue.split('').every(x => x === "0")) {
      filteredValue = "0";
    } else if (filteredValue.split('')[0] === "0" && filteredValue.split('')[1] === "."){
      filteredValue = filteredValue;
    } else {
      filteredValue = filteredValue.replace(/^0+/, '');
    }
    this.props.callback(this.props.id, filteredValue);
  }

  render() {
    return (
      <div className="input-line">
        <div className="input-line-box">
          <div className="input-line-name">{this.props.name}</div>
            <input type="text" value={this.props.value} onChange={this.handleChange} aria-label={this.props.name} />
        </div>
        <div className="input-line-unit"><span>{this.props.unit}</span></div>
      </div>
    );
  }
}

export default Calculator