import React from 'react';
import './Button.css';

class Button extends React.Component {
  constructor(props) {
    super(props);
    this.handleActivate = this.handleActivate.bind(this);
  }
  handleActivate(event) {
    this.props.callback();
  }
  render() {
    return (
      <button className="material-button" onClick={this.handleActivate}>{this.props.name}</button>
    );
  }
}

export default Button