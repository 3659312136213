//global imports
import React from 'react';
import { Container} from 'react-bootstrap';
import {
  Switch,
  Route,
  Link
} from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

//local imports
import SocialBox from "./../socialbox/socialbox";
import navbarStyle from "./navbar.module.scss";

const Navbar = () => {
  return (
    <header className={navbarStyle.navbar}>
      <Container>
          <div className={navbarStyle.content}>
            <div className={navbarStyle.title}>KANBAN CALCULATOR</div>
            <Switch>
              <Route exact path="/">
                <SocialBox />
              </Route>
              <Route path="*">
                <div className={navbarStyle.backlink}><Link to="/"><FaArrowLeft /> Back to the calculator</Link></div>
              </Route>
            </Switch>
          </div>
      </Container>
    </header>
  );
}

export default Navbar;