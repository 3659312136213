//global imports
import React from 'react';
import { 
  Container, 
  Row, 
  Col 
} from 'react-bootstrap';
import { Link } from "react-router-dom";

//local imports
import footerStyle from "./footer.module.scss";
import footerLogo from "./footer-kanban-logo.svg";

const Footer = () => {
  return (
    <footer className={footerStyle.footer} id="footer">
      <Container>
        <Row>
          <Col className={footerStyle.column} lg="5" md="6">
            <div className={footerStyle.logo}>
              <img src={footerLogo} alt="Kanban in Japanese" />
            </div>
          </Col>
          <Col className={`${footerStyle.column} ${footerStyle.desktopLinks}`} lg="3" md="3">
            <p><b>CALCULATOR</b></p>
            <p><Link to="/about">About</Link></p>
            <p><Link to="/feedback">Feedback</Link></p>
            </Col>
          <Col className={`${footerStyle.column} ${footerStyle.desktopLinks}`} lg="3" md="3">
            <p><b>IMPRINT</b></p>
            <p><Link to="/tos">Terms of service</Link></p>
            <p><Link to="/privacy">Privacy Policy</Link></p>
          </Col>
        </Row>
        <div className={footerStyle.mobileLinks}>
          <Link to="/feedback">Feedback</Link>
          <Link to="/tos">Terms of service</Link>
          <Link to="/privacy">Privacy Policy</Link>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;