//global imports
import React from 'react';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaLink
} from "react-icons/fa";

//local imports
import sharebuttonStyle from "./sharebutton.module.scss";

class ShareButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    window.open(this.props.url, '_blank');
  }

  render() {
    switch(this.props.icon) {
      case "facebook":
        var icon = <FaFacebookF />;
        break;
      case "linkedin":
        var icon = <FaLinkedinIn />;
        break;
      case "twitter":
        var icon = <FaTwitter />;
        break;
      default:
        var icon = <FaLink />;
    }
    return <a href="#" onClick={this.handleClick}><div className={sharebuttonStyle.button}>{icon}</div></a>;
  }
}

export default ShareButton;