//global imports
import React from 'react';

//local imports
import textStyle from './../configs/textstyle.module.scss';

const Feedback = () => {
  return (
    <div className={textStyle.container}>
      <h2>Feedback</h2>
      <p>Feel free to drop feedback or feature requests at <a href="mailto:kanbancalculator@gmail.com">kanbancalculator@gmail.com</a>.</p>
    </div>
  );
}

export default Feedback;