//global imports
import React from 'react';
import { Container } from 'react-bootstrap';

//local imports
import Copyright from "./copyright";
import Footer from "./footer";
import Navbar from "./navbar";

const Layout = (props) => {
  return (
    <div style={{minHeight: "100vh", display: "Grid", gridTemplateRows: "auto 1fr auto auto"}}>
      <Navbar />
      <Container>
        {props.children}
      </Container>
      <Footer />
      <Copyright />
    </div>
  )
}

export default Layout