var variablesSimple = [
  {
    "name" : "Bin size",
    "init" : "",
    "unit" : "items",
    "type" : "input"
  },
  {
    "name" : "Transport batch size",
    "init" : "1",
    "unit" : "bins",
    "type" : "unused"
  },
  {
    "name" : "Sum run times",
    "init" : "",
    "unit" : "weeks/item",
    "type" : "unused"
  },
  {
    "name" : "Sum setup times",
    "init" : "",
    "unit" : "weeks",
    "type" : "unused"
  },
  {
    "name" : "Sum admin times",
    "init" : "",
    "unit" : "weeks",
    "type" : "unused"
  },
  {
    "name" : "Bin replenishment time",
    "init" : "",
    "unit" : "weeks",
    "type" : "input",
    "requirements": undefined,
    "function": (input)=> {return;}
  },
  {
    "name" : "Weekly demand",
    "init" : "",
    "unit" : "items/week",
    "type" : "input"
  },
  {
    "name" : "Safety factor",
    "init" : "",
    "unit" : "",
    "type" : "input"
  },  
  {
    "name" : "Number of bins",
    "init" : "",
    "unit" : "bins",
    "type" : "output",
    "requirements": [0,5,6,7],
    "function": (input) => {return input[5]*input[6]*(1+input[7])/input[0]+1}
  },
  {
    "name" : "WIP",
    "init" : "",
    "unit" : "items",
    "type" : "output",
    "requirements": [0,5,6,7],
    "function": (input) => {return input[5]*input[6]*(1+input[7])+input[0]}
  }
];

var variablesAdvanced = [
  {
    "name" : "Bin size",
    "init" : "",
    "unit" : "items",
    "type" : "input"
  },
  {
    "name" : "Transport batch size",
    "init" : "1",
    "unit" : "bins",
    "type" : "input"
  },
  {
    "name" : "Sum run times",
    "init" : "",
    "unit" : "weeks/item",
    "type" : "input"
  },
  {
    "name" : "Sum setup times",
    "init" : "",
    "unit" : "weeks",
    "type" : "input"
  },
  {
    "name" : "Sum admin times",
    "init" : "",
    "unit" : "weeks",
    "type" : "input"
  },
  {
    "name" : "Bin replenishment time",
    "init" : "",
    "unit" : "weeks",
    "type" : "input",
    "requirements": [0, 1, 2, 3, 4],
    "function": (input)=> {return input[0]*input[1]*input[2]+input[3]+input[4]}
  },
  {
    "name" : "Weekly demand",
    "init" : "",
    "unit" : "items/week",
    "type" : "input"
  },

  {
    "name" : "Safety factor",
    "init" : "",
    "unit" : "",
    "type" : "input"
  },  
  {
    "name" : "Number of bins",
    "init" : "",
    "unit" : "bins",
    "type" : "output",
    "requirements": [0,1,5,6,7],
    "function": (input) => {return input[5]*input[6]*(1+input[7])/input[0]+input[1]}
  },
  {
    "name" : "WIP",
    "init" : "",
    "unit" : "items",
    "type" : "output",
    "requirements": [0,1,5,6,7],
    "function": (input) => {return input[5]*input[6]*(1+input[7])+input[1]*input[0]}
  }
];

export { variablesSimple, variablesAdvanced }; 