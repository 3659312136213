//global imports
import React from 'react';

//local imports
import ShareButton from "./../sharebutton/sharebutton";
import socialboxStyle from "./socialbox.module.scss";

const SocialBox = () => {
  return (
    <div className={socialboxStyle.wrapper}>
      <div className={socialboxStyle.text}>
      Share via
      </div>
      <div className={socialboxStyle.container}>
        <ShareButton url="https://www.facebook.com/sharer/sharer.php?u=http://www.kanban-calculator.com/" icon="facebook"></ShareButton>
        <ShareButton url='https://www.linkedin.com/shareArticle?mini=true&url=http://www.kanban-calculator.com/&title=Handy Lean Manufacturing Tool&summary=I found this handy tool, which helps you determine the right kanban size for your supply chain.' icon="linkedin"></ShareButton>
        <ShareButton url="https://twitter.com/home?status=I%20found%20this%20handy%20tool,%20which%20helps%20you%20determine%20the%20right%20kanban%20size%20for%20your%20supply%20chain." icon="twitter"></ShareButton>
      </div>
    </div>
  );
}

export default SocialBox;