//global imports
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

//screen import
import About from './screens/about';
import Feedback from './screens/feedback';
import Main from './screens/main';
import Privacy from './screens/privacy';
import Tos from './screens/tos';
import Notfound from './screens/notfound';

//local imports
import Layout from './components/layout/layout';

class App extends React.Component {
  render() {
    return (
      <Router>
        <Layout>
          <Switch>
            <Route exact path="/">
              <Main />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/feedback">
              <Feedback />
            </Route>
            <Route path="/tos">
              <Tos />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="*">
              <Notfound />
            </Route>
          </Switch>
        </Layout>
      </Router>
    );
  }
}

export default App;
