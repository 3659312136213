//import globals
import React from 'react';

//import locals
import copyrightStyle from './copyright.module.scss';

const Copyright = () => {
  return (
    <div className={copyrightStyle.copyright}>
      &copy; {new Date().getFullYear()} - <a href="http://www.uelibaumgartner.com/">Ueli Baumgartner</a>
    </div>
  );
}

export default Copyright;